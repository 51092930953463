import { mapHelper } from "@/utils/utils";

// 订单状态1->待发货；2->已发货；3->已收货;11->已取消
const orderStatus = [
  {
    text: "待确认",
    value: 1,
  },
  {
    text: "已确认",
    value: 2,
  },
  {
    text: "已收货",
    value: 3,
  },
  {
    text: "已取消",
    value: 11,
  },
];

const { map: orderStatusMap, setOps: orderStatusOps } =
  mapHelper.setMap(orderStatus);

export { orderStatusMap, orderStatus };
