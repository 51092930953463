<template>
  <div class="orderMealList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div class="oItem" v-for="(item, index) in listData" :key="index">
        <div class="oItem-t">
          <div class="oItem-tl">工单号:{{ item.orderNo }}</div>
          <div
            :class="[
              'oItem-tr',
              { active: item.orderStatus == 1 || item.orderStatus == 2 },
            ]"
          >
            {{ orderStatusMap[item.orderStatus] }}
          </div>
        </div>
        <div class="oItem-c">
          <div
            class="item"
            v-for="(oItem, oIndex) in item.mealInfoList"
            :key="oIndex"
          >
            <div class="item-l"><img :src="oItem.mealImageUrls" alt="" /></div>
            <div class="item-r">
              <div class="title">{{ oItem.mealTitle }}</div>
              <div class="des">{{ oItem.mealShortDesc }}</div>

              <div class="num">
                <div class="txt">x{{ oItem.mealNum }}</div>
              </div>
              <div class="price">¥ {{ oItem.mealPrice }}</div>
            </div>
          </div>
          <div class="tel" @click="totel">
            <img src="./img/tel.png" alt="" />
          </div>
          <div class="time">
            <div class="time-t">下单时间:{{ item.intime }}</div>
            <div class="time-b">
              订餐配送月份:{{ returnMonth(item.orderTime) }}
            </div>
            <div class="total">总计：¥ {{ item.totalAmount }}</div>
          </div>
        </div>
        <div class="oItem-b">
          <div class="oItem-bl"></div>
          <div class="oItem-br">
            <div
              class="btn-l"
              v-if="item.orderStatus == 1"
              @click="cancelOrder(item)"
            >
              取消订单
            </div>
            <!-- <div
              class="btn-c"
              v-if="item.orderStatus == 2"
              @click="sureOrder(item)"
            >
              确定收货
            </div> -->
            <div
              class="btn-r"
              v-if="item.orderStatus == 11 || item.orderStatus == 3"
              @click="toAgainOrder"
            >
              再次预定
            </div>
          </div>
        </div>
      </div>
    </v-list>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <div class="tips">
        <div class="tips-t">确定要取消订单？</div>
      </div>
    </v-dialog>
    <v-dialog v-model="isODialog" title="提示" @confirm="confirmOrder">
      <div class="tips">
        <div class="tips-t">确定要确认收货？</div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { myOrderUrl, cancelOrderUrl, sureOrderUrl, isTelShow } from "./api.js";
import { orderStatusMap } from "./map.js";
var moment = require("moment");
export default {
  name: "orderMealList",
  data() {
    return {
      isODialog: false,
      isDialog: false,
      orderStatusMap,
      listData: [],
      finishedText: "",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },

      requestData: {
        curPage: 1,
        pageSize: 10,
        activityCategory: 1,
      },
      item: {},
      tel: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.telList();
    // gloabalCount("", 20, 1);
  },
  methods: {
    returnMonth(data) {
      if (data) {
        return moment(data).format("YYYY-MM");
      }
    },
    totel() {
      window.location.href = `tel:${this.tel}`;
    },
    async telList() {
      let params = {
        applicationName: "点餐商家端",
        functionName: "有权限号码",
      };
      let res = await this.$axios.get(`${isTelShow}`, { params });
      if (res.code === 200) {
        let str = res.data.settingOn || "";
        this.tel = str.split(",")[0];
      }
    },
    toAgainOrder() {
      this.$router.replace({
        name: "orderMeal",
      });
    },
    cancelOrder(item) {
      this.isDialog = true;
      this.item = item;
    },
    sureOrder(item) {
      this.isODialog = true;
      this.item = item;
    },
    async confirm() {
      let params = {
        userId: this.userId,
        orderId: this.item.id,
      };
      let res = await this.$axios.post(
        `${cancelOrderUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.init();
      }
    },
    async confirmOrder() {
      let params = {
        userId: this.userId,
        orderId: this.item.id,
      };
      let res = await this.$axios.post(
        `${sureOrderUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.init();
      }
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.isDialog = false;
      this.isODialog = false;
      this.getMealList();
    },
    getMealList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios.get(`${myOrderUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            item.num = 0;
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getMealList();
    },
  },
};
</script>
<style lang="less" scoped>
.orderMealList {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  background: #f5f5f5;
  padding: 46px 30px;
  .oItem {
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 46px;
    .oItem-t {
      display: flex;
      padding: 16px 24px;
      border-bottom: 2px solid #e9e9e9;
      justify-content: space-between;
      .oItem-tl {
        font-size: 28px;
        font-weight: 400;
        color: #151c39;
        line-height: 40px;
      }
      .oItem-tr {
        font-size: 28px;
        font-weight: 400;
        color: #b0b1b4;
        line-height: 40px;
      }
      .active {
        color: #007eff;
      }
    }
  }
  .oItem-c {
    margin: 32px 0 6px;
    padding: 0 24px 18px;
    border-bottom: 2px solid #e9e9e9;
    .item {
      display: flex;
      height: 136px;
      position: relative;
      margin-bottom: 24px;
      .item-l {
        width: 136px;
        background: #bababa;
        border-radius: 4px;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .item-r {
        flex: 1;
        min-width: 0;
        padding-right: 140px;
        .title {
          font-size: 28px;
          font-weight: 400;
          color: #151c39;
          line-height: 40px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 12px;
        }
        .des {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 24px;
          font-weight: 400;
          color: #151c39;
          line-height: 34px;
        }
        .orderNum {
          font-size: 28px;
          font-weight: 400;
          color: #b4b4b4;
          line-height: 40px;
          margin-bottom: 42px;
        }
        .num {
          display: flex;
          align-content: center;
          position: absolute;
          top: 52px;
          right: 10px;
          .minus,
          .add {
            width: 50px;
            height: 50px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            font-size: 24px;
            font-weight: 400;
            color: #b0b1b4;
            line-height: 34px;
          }
        }
        .price {
          font-size: 28px;
          font-weight: 600;
          color: #151c39;
          line-height: 40px;
          position: absolute;
          top: 0;
          right: 10px;
        }
      }
    }
    .tel {
      text-align: right;
      img {
        width: 48px;
        height: 48px;
      }
    }
    .time {
      position: relative;
      .time-t,
      .time-b {
        font-size: 24px;
        font-weight: 400;
        color: #151c39;
        line-height: 34px;
        margin-bottom: 12px;
      }
      .total {
        position: absolute;
        font-size: 28px;
        font-weight: 600;
        color: #151c39;
        line-height: 40px;
        top: 0;
        right: 0;
      }
    }
  }
  .oItem-b {
    display: flex;
    justify-content: space-between;
    padding: 14px 24px 14px 0;
    .oItem-br {
      display: flex;
    }
    .btn-l,
    .btn-c,
    .btn-r {
      width: 152px;
      height: 48px;
      border-radius: 23px;
      border: 2px solid #b0b1b4;
      text-align: center;
      font-size: 28px;
      font-weight: 400;
      color: #b0b1b4;
      line-height: 48px;
      margin-left: 40px;
    }
  }
  .tips {
    .tips-t {
      font-size: 34px;
      text-align: center;
      padding: 0 20px;
      margin: 40px 0;
    }
    .tips-b {
      display: flex;
      margin: 40px 0;
      justify-content: center;
      align-items: center;
      .tips-bl {
        width: 30px;
        height: 30px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tips-br {
        font-size: 28px;
      }
    }
  }
}
</style>
<style lang="less">
.orderMealList {
}
</style>
