let origin = "https://future.zhuneng.cn";
//点餐套餐
const mealListUrl = `/activityApi/api/meal/mealList`;
//房号列表
const roomListUrl = `/miniApi/user/mini/user-asset-list`;
//用户信息
const userInfoUrl = `/miniApi/user/mini/user-info`;
//用户加入
const addCartUrl = `/activityApi/api/meal/addGoodsCart`;
//购物车
const myCartUrl = `/activityApi/api/meal/myGoodsCart`;
//下单
const toOrderUrl = `/activityApi/api/meal/submitOrder`;
//我的订单
const myOrderUrl = `/activityApi/api/meal/myOrderList`;
//取消订单
const cancelOrderUrl = `/activityApi/api/meal/cancelOrder`;
//确认收货
const sureOrderUrl = `/activityApi/api/meal/confirmReceive`;
//确认接单
const sureAcceptUrl = `/activityApi/api/meal/orderSend`;
//电话列表
const isTelShow = `/accApi/accPoints/application/getFunctionSetting`;
//商家订单列表
const bussiOrderUrl = `/activityApi/api/meal/allOrderList`;
export {
  mealListUrl,
  roomListUrl,
  userInfoUrl,
  addCartUrl,
  myCartUrl,
  toOrderUrl,
  myOrderUrl,
  cancelOrderUrl,
  sureOrderUrl,
  sureAcceptUrl,
  isTelShow,
  bussiOrderUrl,
};
